<br class="br" />
<ng-container *ngFor="let page of viewItems; let i = index">
  <div class="a4-paper p-3">
    <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>
    <!-- <app-header-v4 [model]="model"></app-header-v4> -->
    <app-header-shares [model]="model"></app-header-shares>
    <div class="content">
      <div class="item-content">
        <div class="box-content">
          <table class="table">
            <thead class="headers" *ngIf="page.items.length > 0">
              <tr>
                <th>{{'No'| translate}}</th>
                <th>{{'CODE'| translate}} <div class="min-65"></div>
                </th>
                <th class="text-center">{{'PARTICULARS'| translate}}</th>
                <th class="text-start">{{'UNIT'| translate}}</th>
                <th class="text-end">{{'QTY'| translate}}</th>
                <th class="text-end" *ngIf="quotationItems.price_show">
                  {{'UNIT PRICE'| translate}}
                  <div class="min-65"></div>
                </th>
                <th class="text-end" *ngIf="quotationItems.price_show">
                  {{'AMOUNT'| translate}}
                  <div class="min-65"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="sub-content" *ngFor="let item of page.items; let i = index">
                <td>{{ item.id }}</td>
                <td>{{ item.product_code || "***" }}</td>
                <td class="detail">
                  {{ item.product_name }}
                  <br />
                  <span *ngIf="item.product_description">{{
                    item.product_description
                    }}</span>
                </td>
                <td class="text-start" *ngIf="item.unit_name">
                  {{ item.unit_name }}
                </td>
                <td class="text-end">{{ item.quantity }}</td>
                <td class="text-end" *ngIf="quotationItems.price_show">
                  {{ item.unit_price | number : "1.2-2" }}
                </td>
                <td class="text-end" *ngIf="quotationItems.price_show">
                  {{ item.total_price | number : "1.2-2" }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="page.footer" class="footer">
              <tr *ngIf="quotationItems.price_show">
                <td class="note" colspan="4" [attr.rowspan]="numberRow">
                  <b>{{'Notes'| translate}}</b>
                  <div class="note-content">{{ quotationItems.quot_note }}</div>
                </td>
                <td colspan="2">{{'Sub Total'| translate}}</td>
                <td class="text-end">
                  {{ quotationItems.price_sub_total | number : "1.2-2" }}
                </td>
              </tr>
              <tr *ngIf="
                  quotationItems.discount_amount > 0 &&
                  quotationItems.price_show
                ">
                <td colspan="2">{{'Discount'| translate}}
                  <span class="ms-2" *ngIf="quotationItems.discount_type=='P'">{{quotationItems.discount_amount}}
                    %</span>
                </td>
                <td class="text-end">
                  <span *ngIf="quotationItems.discount_type !='P'">
                    {{quotationItems.discount_amount | number:'1.2-2'}}
                  </span>
                  <span *ngIf="quotationItems.discount_type =='P'">
                    {{disAmount | number:'1.2-2'}}
                  </span>

                </td>
              </tr>
              <tr *ngIf="quotationItems.price_show">
                <td colspan="2">{{'Net Total'| translate}}</td>
                <td class="text-end">
                  {{ quotationItems.price_net_total | number : "1.2-2" }}
                </td>
              </tr>
              <tr *ngIf="
                  quotationItems.vat_type != 'N' && quotationItems.price_show
                ">
                <td colspan="2">
                  {{'Vat'| translate}} {{ quotationItems.vat_percent || "0" }}%
                </td>
                <td class="text-end">
                  {{ quotationItems.price_vat | number : "1.2-2" }}
                </td>
              </tr>
              <tr class="tr-footer">
                <!-- [attr.colspan]="cpp" -->
                <td [attr.colspan]="cpp" class="text-center">
                  {{
                  quotationItems.price_grand_total | fixnumber | totalstring
                  }}
                </td>
                <td [attr.colspan]="ctp">
                  <span *ngIf="quotationItems.price_show">{{'Grand Total'| translate}} ({{ quotationItems.currency_code
                    }})</span>
                  <span *ngIf="!quotationItems.price_show">{{'Total'| translate}}</span>
                </td>
                <td class="text-end">
                  <span *ngIf="quotationItems.price_show">
                    {{
                    quotationItems.price_grand_total | number : "1.2-2"
                    }}</span>
                  <span *ngIf="!quotationItems.price_show"> {{ qtp }}</span>
                </td>
              </tr>
              <tr class="border-none">
                <td colspan="7">
                  <br />
                  <p><b>{{'Notes'| translate}}</b></p>
                  <span>{{'Customer Notes'| translate}}</span>
                  <!-- <p class="mt-2">Prices and terms shown above are understood and we confirm this
                                        order.</p> -->
                  <br />
                  <br />
                  <p>
                    {{'The products listed above, even after delivery to the buyer, remain the property of the seller
                    until the buyer has made full payment. The products listed above have been received in correct and
                    proper condition.'| translate}}
                  </p>
                  <br />
                  <p>{{'Received as per above in good order and condition'| translate}}</p>
                </td>
              </tr>
              <tr class="border-none">
                <td colspan="7">
                  <div class="row">
                    <div class="col-4 text-center">
                      <p>{{'Recipient'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Forwarder'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Authorized signatory'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- {{page.page}}
        {{i+1}} -->
    <div class="page text-center" *ngIf="viewItems.length > 1">
      Page <span *ngIf="page.page - 1 == i">{{ page.page }}</span> of
      {{ viewItems.length }}
    </div>
  </div>
</ng-container>

<!-- COPY -->

<ng-container *ngFor="let page of viewItems; let i = index">
  <div class="a4-paper p-3">
    <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>
    <!-- <app-header-v4 [model]="model"></app-header-v4> -->
    <app-header-shares [model]="model" [copy]="true"></app-header-shares>
    <div class="content">
      <div class="item-content">
        <div class="box-content">
          <table class="table">
            <thead class="headers" *ngIf="page.items.length > 0">
              <tr>
                <th>{{'No'| translate}}</th>
                <th>{{'CODE'| translate}}
                  <div class="min-65"></div>
                </th>
                <th class="text-center">{{'PARTICULARS'| translate}}</th>
                <th class="text-start">{{'UNIT'| translate}}</th>
                <th class="text-end">{{'QTY'| translate}}</th>
                <th class="text-end" *ngIf="quotationItems.price_show">
                  {{'UNIT PRICE'| translate}}
                  <div class="min-65"></div>
                </th>
                <th class="text-end" *ngIf="quotationItems.price_show">
                  {{'AMOUNT'| translate}}
                  <div class="min-65"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="sub-content" *ngFor="let item of page.items; let i = index">
                <td>{{ item.id }}</td>
                <td>{{ item.product_code || "***" }}</td>
                <td class="detail">
                  {{ item.product_name }}
                  <br />
                  <span *ngIf="item.product_description">{{
                    item.product_description
                    }}</span>
                </td>
                <td class="text-start" *ngIf="item.unit_name">
                  {{ item.unit_name }}
                </td>
                <td class="text-end">{{ item.quantity }}</td>
                <td class="text-end" *ngIf="quotationItems.price_show">
                  {{ item.unit_price | number : "1.2-2" }}
                </td>
                <td class="text-end" *ngIf="quotationItems.price_show">
                  {{ item.total_price | number : "1.2-2" }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="page.footer" class="footer">
              <tr *ngIf="quotationItems.price_show">
                <td class="note" colspan="4" [attr.rowspan]="numberRow">
                  <b>{{'Notes'| translate}}</b>
                  <div class="note-content">{{ quotationItems.quot_note }}</div>
                </td>
                <td colspan="2">{{'Sub Total'| translate}}</td>
                <td class="text-end">
                  {{ quotationItems.price_sub_total | number : "1.2-2" }}
                </td>
              </tr>
              <tr *ngIf="
                  quotationItems.discount_amount > 0 &&
                  quotationItems.price_show
                ">
                <td colspan="2">{{'Discount'| translate}}
                  <span class="ms-2" *ngIf="quotationItems.discount_type=='P'">{{quotationItems.discount_amount}}
                    %</span>
                </td>
                <td class="text-end">
                  <span *ngIf="quotationItems.discount_type !='P'">
                    {{quotationItems.discount_amount | number:'1.2-2'}}
                  </span>
                  <span *ngIf="quotationItems.discount_type =='P'">
                    {{disAmount | number:'1.2-2'}}
                  </span>

                </td>
              </tr>
              <tr *ngIf="quotationItems.price_show">
                <td colspan="2">{{'Net Total'| translate}}</td>
                <td class="text-end">
                  {{ quotationItems.price_net_total | number : "1.2-2" }}
                </td>
              </tr>
              <tr *ngIf="
                  quotationItems.vat_type != 'N' && quotationItems.price_show
                ">
                <td colspan="2">
                  {{'Vat'| translate}} {{ quotationItems.vat_percent || "0" }}%
                </td>
                <td class="text-end">
                  {{ quotationItems.price_vat | number : "1.2-2" }}
                </td>
              </tr>
              <tr class="tr-footer">
                <!-- [attr.colspan]="cpp" -->
                <td [attr.colspan]="cpp" class="text-center">
                  {{
                  quotationItems.price_grand_total | fixnumber | totalstring
                  }}
                </td>
                <td [attr.colspan]="ctp">
                  <span *ngIf="quotationItems.price_show">{{'Grand Total'| translate}} ({{ quotationItems.currency_code
                    }})</span>
                  <span *ngIf="!quotationItems.price_show">{{'Total'| translate}}</span>
                </td>
                <td class="text-end">
                  <span *ngIf="quotationItems.price_show">
                    {{
                    quotationItems.price_grand_total | number : "1.2-2"
                    }}</span>
                  <span *ngIf="!quotationItems.price_show"> {{ qtp }}</span>
                </td>
              </tr>
              <tr class="border-none">
                <td colspan="7">
                  <br />
                  <p><b>{{'Notes'| translate}}</b></p>
                  <span>{{'Customer Notes'| translate}}</span>
                  <!-- <p class="mt-2">Prices and terms shown above are understood and we confirm this
                                        order.</p> -->
                  <br />
                  <br />
                  <p>
                    {{'The products listed above, even after delivery to the buyer, remain the property of the seller
                    until the buyer has made full payment. The products listed above have been received in correct and
                    proper condition.'| translate}}
                  </p>
                  <br />
                  <p>{{'Received as per above in good order and condition'| translate}}</p>
                </td>
              </tr>
              <tr class="border-none">
                <td colspan="7">
                  <div class="row">
                    <div class="col-4 text-center">
                      <p>{{'Recipient'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Forwarder'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Authorized signatory'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- {{page.page}}
        {{i+1}} -->
    <div class="page text-center" *ngIf="viewItems.length > 1">
      Page <span *ngIf="page.page - 1 == i">{{ page.page }}</span> of
      {{ viewItems.length }}
    </div>
  </div>
</ng-container>
<br class="br" />