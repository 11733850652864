<br class="br">
<ng-container *ngIf="expire_date">
    <h1 class="text-center" style="margin-top: 40vh; "><b>This link has expired.</b></h1>
</ng-container>

<ng-container *ngIf="!expire_date && tn.isLoadingView">
    <ng-container *ngFor="let page of viewItems; let i = index">
        <div class="a4-paper p-3">
            <!-- <app-header-v4 [model]="model"></app-header-v4> -->
            <app-header-shares [model]="model"></app-header-shares>
            <div class="content">
                <div class="item-content">
                    <div class="box-content">
                        <table class="table">
                            <thead class="headers" *ngIf="page.items.length > 0">
                                <tr>
                                    <th [width]="60">{{'No'| translate}}</th>
                                    <th>
                                        {{'CODE'| translate}}
                                        <div class="min-65"></div>
                                    </th>
                                    <th class="text-center">
                                        {{'PARTICULARS'| translate}}
                                    </th>
                                    <th class="text-start">
                                        {{'UNIT'| translate}}
                                    </th>
                                    <th class="text-end">
                                        {{'QTY'| translate}}
                                    </th>
                                    <th [width]="160" class="text-end">
                                        {{'UNIT PRICE'| translate}}
                                        <div class="min-65"></div>
                                    </th>
                                    <th class="text-end">
                                        {{'AMOUNT'| translate}}
                                        <div class="min-65"></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="sub-content" *ngFor="let item of page.items;let i=index;">
                                    <td>{{item.id}}</td>
                                    <td>{{item.product_code || '***'}}</td>
                                    <td class="detail">
                                        {{item.product_name}}
                                        <br>
                                        <span *ngIf="item.product_description">{{item.product_description}}</span>
                                    </td>
                                    <td class="text-start" *ngIf="item.unit_name"> {{item.unit_name}}</td>
                                    <td class="text-end"> {{item.quantity}}</td>
                                    <td class="text-end"> {{item.unit_price | number:'1.2-2'}}</td>
                                    <td class="text-end"> {{item.total_price | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="page.footer" class="footer">
                                <tr>
                                    <td class="note" colspan="4" [attr.rowspan]="numberRow">
                                        <b> {{'Notes'| translate}} {{numberRow}}</b>
                                        <div class="note-content">{{quotationItems.quot_note}}</div>
                                    </td>
                                    <td colspan="2">{{'Sub Total'| translate}}</td>
                                    <td class="text-end">{{quotationItems.price_sub_total
                                        | number:'1.2-2'}}</td>
                                </tr>
                                <tr *ngIf="quotationItems.discount_amount > 0">
                                    <td colspan="2">{{'Discount'| translate}}
                                        <span class="ms-2"
                                            *ngIf="quotationItems.discount_type=='P'">{{quotationItems.discount_amount}}
                                            %</span>
                                    </td>
                                    <td class="text-end">
                                        <span *ngIf="quotationItems.discount_type !='P'">
                                            {{quotationItems.discount_amount | number:'1.2-2'}}
                                        </span>
                                        <span *ngIf="quotationItems.discount_type =='P'">
                                            {{disAmount | number:'1.2-2'}}
                                        </span>

                                    </td>
                                </tr>
                                <tr *ngIf="quotationItems.discount_amount > 0">
                                    <td colspan="2">{{'Net Total'| translate}}</td>
                                    <td class="text-end">{{quotationItems.price_net_total | number:'1.2-2'}}</td>
                                </tr>
                                <tr *ngIf="quotationItems.vat_type !='N'">
                                    <td colspan="2">{{'Vat'| translate}}{{quotationItems.vat_percent|| '0'}}%</td>
                                    <td class="text-end"> {{quotationItems.price_vat | number:'1.2-2'}}</td>
                                </tr>
                                <tr class="tr-footer">
                                    <td colspan="4" class="text-center">{{quotationItems.price_grand_total | fixnumber |
                                        totalstring}} </td>
                                    <td colspan="2">{{'Grand Total'| translate}}({{quotationItems.currency_code}})</td>
                                    <td class="text-end">{{quotationItems.price_grand_total |
                                        number:'1.2-2'}}</td>
                                </tr>
                                <tr class="border-none">
                                    <td colspan="7">
                                        <b>{{'Payment Method'| translate}}</b>
                                        <div class="box-payment mt-2">
                                            <div class="box-image" *ngIf="payment_images">
                                                <img [src]="payment_images" alt="">
                                            </div>
                                            <p class="ms-2"> {{quotationItems.payment_method_description}}</p>
                                        </div>
                                        <p class="mt-2">{{'Prices and terms shown above are understood and we confirm this order.'| translate}}
                                        </p>
                                    </td>
                                </tr>
                                <tr class="border-none">
                                    <td colspan="7">
                                        <div class="row">
                                            <div class="col-6 text-center">
                                                <p>{{'Authorized Signature'| translate}}</p>
                                                <p class="mt-2">
                                                    ....................................................................
                                                </p>
                                                <p class="mt-2">
                                                    (....................................................................)
                                                </p>
                                                <p class="mt-2">{{'Date'| translate}}
                                                    ....................................................................
                                                </p>
                                            </div>
                                            <div class="col-6 text-center">
                                                <p>{{'This quote is prepared by'| translate}}</p>
                                                <p style="font-family: 'Qwitcher Grypen', cursive;">
                                                    {{quotationItems.create_by_fullname}} </p>
                                                <p>( {{quotationItems.create_by_fullname}} )</p>
                                                <p>{{'Sales Representative'| translate}}</p>
                                                <p *ngIf="quotationItems.create_by_full_phone_number">
                                                    <span> {{'Tel'| translate}}:
                                                        +{{quotationItems.create_by_country_code}}{{quotationItems.create_by_phone_number}}</span>
                                                    <span *ngIf="quotationItems.create_by_email_address"> / {{'Email'| translate}}:
                                                        {{quotationItems.create_by_email_address}}</span>
                                                </p>
                                                <p>{{'Date'| translate}}: {{quotationItems.create_date | date:'dd/MM/YYYY'}}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <!-- {{page.page}}
        {{i+1}} -->
            <div class="page text-center" *ngIf="viewItems.length > 1">Page <span
                    *ngIf="page.page -1 == i">{{page.page}}</span>
                of {{viewItems.length}}
            </div>
        </div>
    </ng-container>
</ng-container>
<br class="br">